<template>
    <div>
        <h1 v-if="pageH1Title" class="m_page_title">
            {{ pageH1Title }}
        </h1>
        <div 
            v-if="empty" 
            class="mt-5">
            <a-empty description="Нет данных" />
        </div>
        <MeetingCard 
            v-for="item in meetings.results" 
            :key="item.id" 
            :isScrolling="isScrolling"
            :page_name="page_name"
            :item="item" />
        <infinite-loading 
            ref="meeting_infinity"
            @infinite="getMeetings"
            :identifier="infiniteId"
            v-bind:distance="10">
            <div 
                slot="spinner"
                class="flex items-center justify-center inf_spinner">
                <a-spin />
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
    </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import MeetingCard from './MeetingCard.vue'
import eventBusGlobal from '@/utils/eventBus'
import eventBus from '../utils/eventBus'
export default {
    name: 'MeetingTypeList',
    components: {
        MeetingCard,
        InfiniteLoading
    },
    props: {
        pageModel: {
            type: String,
            default: 'meetings.PlannedMeetingModel'
        },
        page_name: {
            type: String,
            default: 'page_list_meetings.PlannedMeetingModel'
        },
        isScrolling: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        pageH1Title() {
            return this.$route?.meta?.title ? this.$route.meta.title : null
        }
    },
    data() {
        return {
            loading: false,
            page: 0,
            empty: false,
            infiniteId: this.page_name,
            meetings: {
                results: [],
                next: true,
                count: 0
            }
        }
    },
    methods: {
        async getMeetings($state) {
            if(!this.loading && this.meetings.next) {
                try {
                    this.loading = true
                    this.page += 1
                    const { data } = await this.$http.get('/meetings/', {
                        params: {
                            page: this.page,
                            page_size: 15,
                            page_name: this.page_name,
                            model: this.pageModel
                        }
                    })

                    if(data) {
                        this.meetings.count = data.count
                        this.meetings.next = data.next
                    }

                    if(data?.results?.length)
                        this.meetings.results = this.meetings.results.concat(data.results)

                    if(this.page === 1 && !this.meetings.results.length) {
                        this.empty = true
                    }  
                    if(this.meetings.next)
                        $state.loaded()
                    else
                        $state.complete()
                } catch(e) {
                    console.log(e)
                } finally {
                    this.loading = false
                }
            }
        },
        endConference(id) {
            const index = this.meetings.results.findIndex(f => f.id === id)
            if(index !== -1)
                this.meetings.results[index].status = 'ended'
        },
        restartConference(id) {
            const index = this.meetings.results.findIndex(f => f.id === id)
            if(index !== -1)
                this.meetings.results[index].status = 'new'
        },
        listReload() {
            this.$nextTick(() => {
                this.page = 0
                this.empty = false
                this.meetings = {
                    results: [],
                    next: true,
                    count: 0
                }
                this.$refs['meeting_infinity'].stateChanger.reset()
            })
        }
    },
    mounted() {
        eventBusGlobal.$on(`update_filter_${this.pageModel}`, () => {
            this.listReload()
        })
        eventBus.$on(`reload_list_${this.page_name}`, (page_default = false) => {
            this.listReload()
        })
        eventBus.$on('reload_meetings_list',  (page_default = false) => {
            this.listReload()
        })
        eventBus.$on('END_CONFERENCE', id => {
            this.endConference(id)
        })
        eventBus.$on('RESTART_CONFERENCE', id => {
            this.restartConference(id)
        })
    },
    beforeDestroy() {
        eventBusGlobal.$off(`update_filter_${this.pageModel}`)
        eventBus.$off(`reload_list_${this.page_name}`)
        eventBus.$off('reload_meetings_list')
        eventBus.$off('END_CONFERENCE')
        eventBus.$off('RESTART_CONFERENCE')
    }
}
</script>